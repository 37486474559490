<template>
  <div
    class="confirm-container deposit-confirm d-flex flex-column align-items-center"
  >
    <div class="shield-icon">
      <i class="icon-shield-check" />
    </div>
    <h2 class="f-w-600 text-center">
      {{ data.title }}
    </h2>
    <h5 class="text-center">
      <span v-if="data.message">{{ data.message }}</span>
      <br />
      <span v-if="data.ussd" class="mt-2 f-w-500">
        {{ $t("deposit.please_compose_before") }}
        <b>{{ data.ussd }}</b>
        {{ $t("deposit.please_compose_after") }}
      </span>
    </h5>
    <p v-if="data.cancel">
      {{ data.cancel }}
    </p>
    <p v-if="data.note" class="mt-2 f-w-500">
      {{ data.note }}
    </p>
    <p v-if="data.alert" class="mt-2 f-w-500 text-center">
      {{ data.alert }}
    </p>
    <div class="loading-container">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DepositInProcess",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
