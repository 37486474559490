<template>
  <div class="d-flex mt-4 mt-lg-0">
    <div class="w-100px col-11 col-lg-10 flex-1 px-0 pr-2">
      <be-multiselect
        v-model="phone_number_select"
        :inputLabel="inputLabel"
        :options="numbers"
        :placeholder="$t('common.choose')"
        close-on-select
        label="svc_number"
        onlyKey
        track-by="svc_number"
        @input="closePlaceholder"
      />
    </div>
    <div class="edit-btn mt-4 pt-2">
      <button
        :disabled="!canAddNumber"
        :title="$t('common.add')"
        class="be-btn secondary blue circle-btn lg"
        role="button"
        type="button"
        @click="openUserServiceForm"
      >
        <i class="icon-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formFieldMixin } from "@/mixins/formFieldMixin";
import BeMultiselect from "@/components/common/BeMultiselect";

export default {
  name: "PhoneNumberSelect",
  components: { BeMultiselect },
  mixins: [formFieldMixin],
  props: {
    operator: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onToggleEdit: {
      type: Function,
      default: () => {},
    },
    inputLabel: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      phone_number_select: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["calling_code"]),
    numbers() {
      return this.operator.numbers || [];
    },
    canAddNumber() {
      if (this.operator.setting?.max_number) {
        return this.numbers.length < parseInt(this.operator.setting.max_number);
      } else return true;
    },
    canShowCancelBtn() {
      return this.numbers.length > 0;
    },
  },

  methods: {
    openUserServiceForm() {
      if (this.canAddNumber) {
        this.onToggleEdit(true);
      }
    },
    closePlaceholder(val) {
      this.$emit("input", this.phone_number_select);
      this.onToggleEdit(val === "-1");
    },
  },
};
</script>

<style scoped></style>
