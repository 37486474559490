<template>
  <div class="retraits-service d-flex h-10 be-row justify-content-center">
    <div class="left-side d-none d-lg-flex">
      <div class="icon-div">
        <i class="icon-deposit-fill"></i>
      </div>
      <span>
        {{ $t("deposit.do_") }} <br />
        {{ $t("deposit.a_deposit") }}
      </span>
    </div>
    <div class="right-side col-xl-8 px-0">
      <div class="be-card col-xl-12 px-0">
        <div class="position-relative">
          <header-with-divider
            :color="'blue'"
            :text="`${$t('deposit.do_')} ${$t('deposit.a_deposit')}`"
          />
        </div>
        <div v-if="isDepositAvailable" class="h-100">
          <InitDepositForm
            v-if="isInitDepositForm"
            @initiated="depositInitiated"
          />

          <DepositInProcess v-if="depositInit" :data="depositInit.data" />

          <BlockchainQRCode
            v-if="blockchainInit"
            :blockchain="blockchainInit.data"
          />
        </div>
        <div v-else>
          <BaseEmptyList :model="listModel" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DepositInProcess from "@/components/deposit/DepositInProcess";
import BlockchainQRCode from "@/components/deposit/BlockchainQRCode";
import { emptyListModel, isPending } from "@/helpers/constants";
import HeaderWithDivider from "@/components/common/HeaderWithDivider";
import InitDepositForm from "@/components/deposit/InitDepositForm.vue";

export default {
  name: "DepositForm",
  components: {
    InitDepositForm,
    BlockchainQRCode,
    DepositInProcess,
    HeaderWithDivider,
  },

  data() {
    return {
      loading: false,
      depositInit: null,
      blockchainInit: null,

      interval: null,
      timeout: null,
    };
  },

  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    if (this.timeout) clearTimeout(this.timeout);
  },

  computed: {
    ...mapGetters("deposit", [
      "operators",
      "isDepositAvailable",
      "getOperatorById",
      "userConfig",
    ]),

    listModel() {
      return (
        this.userConfig?.deposit_error_msg ?? emptyListModel.disableDeposit
      );
    },
    isInitDepositForm() {
      return !this.depositInit && !this.blockchainInit;
    },
  },

  methods: {
    depositInitiated(data) {
      this.depositInit = data;
      this.startDepositVerification();
    },
    startDepositVerification() {
      // if (!this.$store.state.echo_connect) {
      this.manuelVerification(this.depositInit.deposit);
      // }
      // this.$echo
      //   .channel(`deposit-echo-${deposit_id}`)
      //   .listen("Deposit\\DepositUpdatedNotify", payload => {
      //     switch (payload.status) {
      //       case 200:
      //       case 400:
      //         if (payload.status === 200)
      //           this.$store.dispatch("auth/getCurrentUser");
      //
      //         this.$store.dispatch("notification/add", {
      //           type: payload.status === 200 ? "success" : "danger",
      //           message: payload.response.message,
      //           is_toast: false,
      //         });
      //         this.$router.push({ name: "deposit" });
      //         break;
      //       case 201:
      //         if (
      //           this.depositInit &&
      //           !this.depositInit.validate &&
      //           payload.response.validate
      //         )
      //           this.depositInit = payload.response;
      //         break;
      //       case 202:
      //         this.blockchainInit = payload.response;
      //         break;
      //     }
      //   });
      // this.loading = false;
      //
      // this.timeout = setTimeout(
      //   () => this.manuelVerification(deposit_id),
      //   120000
      // );
    },

    manuelVerification(deposit) {
      this.interval = setInterval(() => {
        this.$store
          .dispatch("deposit/verifyDeposit", deposit)
          .then(data => {
            if (!isPending(data.status)) {
              this.$router.push({ name: "deposit" });
            }
          })
          .catch(() => {
            this.$router.push({ name: "deposit" });
          });
      }, 20000);
    },
  },
};
</script>

<style scoped></style>
