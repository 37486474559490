<template>
  <div>
    <div>
      <select
        v-if="isGenderSelect"
        class="form-control"
        :value="value"
        v-on="$listeners"
        v-bind="$attrs"
        :class="{ 'is-invalid': isInvalid }"
        @change="updateValue"
      >
        <option disabled :value="undefined">{{ $t("common.choose") }}</option>
        <option value="male">{{ $t("fields.gender.male") }}</option>
        <option value="female">{{ $t("fields.gender.female") }}</option>
      </select>
      <BaseInput
        v-else
        :type="property.type"
        :label="property.label"
        :class="disabledField && canEditPhone ? 'col-10 f-l pl-0' : ''"
        :isInvalid="isInvalid"
        :isPhoneInput="isPhoneInput"
        :countryCode="calling_code"
        :value="value"
        :disabled="disabledField && !canEditPhone"
        v-bind="$attrs"
        v-on="$listeners"
        :errorMessage="errorMessage"
        :description="inputDesc"
      />
      <slot v-if="!isPhoneInput" />
    </div>
    <slot v-if="isPhoneInput" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formFieldMixin } from "@/mixins/formFieldMixin";
import { propertyCode } from "@/helpers/constants";

export default {
  name: "PropertyInput",
  mixins: [formFieldMixin],
  props: {
    property: {
      type: Object,
      required: true,
    },
    phone: {
      type: [String, Object],
      default: null,
    },
    canEditPhone: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    if (this.isPhoneInput) {
      if (!this.canEditPhone) this.$emit("input", this.phone);
    }
  },

  computed: {
    ...mapGetters("auth", ["calling_code"]),
    isPhoneInput() {
      return this.property.code === propertyCode.phone;
    },
    isGenderSelect() {
      return this.property.code === propertyCode.gender;
    },
    disabledField() {
      return this.isPhoneInput && this.phone;
    },
    inputDesc() {
      return this.property.pivot?.user_action ?? "";
    },
  },
};
</script>

<style scoped></style>
