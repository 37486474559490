<template>
  <div>
    <form class="be-card-body h-100" @submit.prevent="handleDepositForm">
      <be-form-error v-if="error" :message="error" />
      <div class="be-row position-relative">
        <div class="col-lg-6 px-0 pr-lg-4">
          <be-multiselect
            v-model.number="selectOperatorId"
            :close-on-select="true"
            :inputLabel="$t('common.choose_an_operator')"
            :options="operators"
            :placeholder="$t('common.choose_an_operator')"
            label="label"
            onlyKey
            track-by="id"
            withImages
            image-src-key="logo_url"
          />
        </div>
      </div>
      <div class="card-body p-0 p-sm-2">
        <div class="divider my-30"></div>
        <OperatorPhoneNumber
          v-if="openServiceNumberForm"
          :operator="operator"
          @cancel="onToggleEdit(false)"
        />
        <template v-else>
          <div class="be-row p-2 p-sm-0">
            <template v-if="properties.length > 0">
              <div
                v-for="property in properties"
                :key="property.id"
                class="col-xs-12 col-lg-6 px-0 pr-lg-4"
              >
                <template v-if="isPhonePropertyCode(property.code)">
                  <PhoneNumberSelect
                    v-if="canValidateNumber"
                    v-model="depositField[property.code]"
                    :operator="operator"
                    :onToggleEdit="onToggleEdit"
                    :loading="loading"
                    :input-label="property.label"
                  />
                  <PropertyInput
                    v-else
                    v-model="depositField[property.code]"
                    :can-edit-phone="!useUserProfileNumber"
                    :errorMessage="inputValidationError(property)"
                    :is-invalid="$v.depositField[property.code].$error"
                    :phone="phoneWithoutCallingCode"
                    :placeholder="
                      $t('common.insert_the') + ' ' + property.label
                    "
                    :property="property"
                    @blur="$v.depositField[property.code].$touch()"
                  />
                </template>
                <PropertyInput
                  v-else
                  v-model="depositField[property.code]"
                  :errorMessage="inputValidationError(property)"
                  :is-invalid="$v.depositField[property.code].$error"
                  :placeholder="$t('common.insert_the') + ' ' + property.label"
                  :property="property"
                  @blur="$v.depositField[property.code].$touch()"
                />
              </div>
            </template>
            <div class="col-xs-12 col-lg-6 px-0 deposit-input-div">
              <BaseInput
                v-if="!canSelectDepositAmount"
                v-model.number="depositField.amount"
                :disabled="operator === null"
                :errorMessage="amountInputValidation"
                :isInvalid="$v.depositField.amount.$error"
                :label="$t('deposit.deposit_amount')"
                :placeholder="$t('deposit.insert_the_amount')"
                type="number"
                @input="$v.depositField.amount.$touch()"
              />
              <be-multiselect
                v-else
                v-model.number="depositField.amount"
                :close-on-select="true"
                :disabled="amount_list.length === 0"
                :errorMessage="amountInputValidation"
                :inputLabel="$t('deposit.deposit_amount')"
                :isInvalid="$v.depositField.amount.$error"
                :options="amount_list"
                :placeholder="$t('deposit.choose_deposit_amount')"
                label="label"
                onlyKey
                track-by="id"
                @input="$v.depositField.amount.$touch()"
              />
            </div>
          </div>
        </template>
        <div class="divider my-30"></div>
      </div>

      <div class="be-row justify-content-end">
        <div
          class="retrait-main-buttons col be-row justify-content-center col-sm-auto px-0"
        >
          <span
            class="f-w-600 fermer pointer retrait-annuler"
            @click="$router.push({ name: 'deposit' }).catch(() => {})"
          >
            {{ $t("common.return") }}
          </span>
          <BaseButton
            :disabled="$v.$invalid || loading"
            :loading="loading"
            :show-loading="true"
            buttonClass="ml-4 be-btn primary lg blue mb-0"
          >
            {{ $t("deposit.start_a_deposit") }}
          </BaseButton>
        </div>
      </div>
    </form>

    <b-modal id="deposit_modal" ref="detailsModal" no-fade size="lg" top>
      <template #modal-header v-if="modalData">
        <span />
        <span class="modal-title">
          {{ modalData.title }}
        </span>
        <span class="pointer" @click="hideModal">
          <i class="icon-close f-24" />
        </span>
      </template>
      <div class="text-center" v-if="modalData">
        <div class="info-background">
          <i class="icon-info-fill"></i>
        </div>
        {{ modalData.message }}
      </div>
      <template #modal-footer>
        <span class="f-w-600 fermer mr-4 pointer" @click="hideModal">
          {{ $t("common.cancel") }}
        </span>
        <button class="ml-4 be-btn primary lg blue" @click="processToDeposit">
          {{ $t("common.yes_continue") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BeMultiselect from "@/components/common/BeMultiselect.vue";
import OperatorPhoneNumber from "@/components/deposit/OperatorPhoneNumber.vue";
import BeFormError from "@/components/common/BeFormError.vue";
import PhoneNumberSelect from "@/components/deposit/PhoneNumberSelect.vue";
import PropertyInput from "@/components/deposit/PropertyInput.vue";
import { mapGetters } from "vuex";
import { returnBoolValue } from "@/helpers/helpers";
import { isPending, operatorCode, propertyCode } from "@/helpers/constants";
import {
  email,
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  name: "InitDepositForm",
  components: {
    PropertyInput,
    PhoneNumberSelect,
    BeFormError,
    OperatorPhoneNumber,
    BeMultiselect,
  },
  data() {
    return {
      loading: false,
      error: null,
      errors: [],
      isServiceNumberForm: false,
      depositField: {
        operator_id: null,
        amount: null,
      },
      modalData: null,
    };
  },
  computed: {
    ...mapGetters("deposit", ["operators", "getOperatorById"]),
    ...mapGetters("auth", [
      "phoneWithoutCallingCode",
      "phone_length",
      "currency",
    ]),
    selectOperatorId: {
      get() {
        return this.depositField.operator_id;
      },
      set(value) {
        this.depositField = {
          operator_id: null,
          amount: null,
        };
        this.depositField.operator_id = value;
      },
    },
    operator() {
      return this.depositField.operator_id
        ? this.getOperatorById(this.depositField.operator_id)
        : null;
    },
    properties() {
      if (this.operator) return this.operator.provider.properties;
      else return [];
    },
    amount_list() {
      if (this.operator && this.operator.provider.amounts?.length) {
        return this.operator.provider.amounts.map(amount => ({
          id: amount,
          label: amount,
        }));
      } else return [];
    },
    openServiceNumberForm() {
      return (
        this.isServiceNumberForm ||
        (this.canValidateNumber && this.operator.numbers.length === 0)
      );
    },
    canSelectDepositAmount() {
      return this.amount_list.length > 0;
    },
    canValidateNumber() {
      return returnBoolValue(this.operator?.setting.validate_number ?? false);
    },
    useUserProfileNumber() {
      return returnBoolValue(this.operator?.setting.only_user_number ?? false);
    },
    minDepositAmountValue() {
      return parseFloat(this.operator?.setting.deposit_min_amount ?? 500);
    },
    maxDepositAmountValue() {
      return parseFloat(this.operator?.setting.deposit_max_amount ?? 500000);
    },
    amountValidationModulo() {
      return parseFloat(this.operator?.setting.deposit_amount_mod ?? 100);
    },
    amountInputValidation() {
      if (this.$v.depositField.amount.$error) {
        if (!this.$v.depositField.amount.required) {
          return (
            this.$t("common.please_") +
            ` ${
              this.canSelectDepositAmount
                ? this.$t("common.choose_")
                : this.$t("common.input")
            } ` +
            this.$t("deposit.the_deposit_amount")
          );
        }
        if (!this.$v.depositField.amount.minValue) {
          return (
            this.$t("deposit.deposit_amount_must_be_greater_than") +
            ` ${this.minDepositAmountValue} ${this.currency}`
          );
        }
        if (!this.$v.depositField.amount.maxValue) {
          return (
            this.$t("deposit.deposit_amount_must_be_lower_than") +
            ` ${this.maxDepositAmountValue} ${this.currency}`
          );
        }
      }
      return "";
    },
    amountTaxConfig() {
      if (this.operator) {
        if (this.operator.provider.with_tax_support) {
          const taxConfig = this.operator.taxes.find(
            tax =>
              this.depositField.amount >= tax.min &&
              this.depositField.amount <= tax.max
          );
          return taxConfig !== undefined
            ? {
                tax: taxConfig.tax,
                is_percent: taxConfig.is_percent,
              }
            : null;
        }
      }
      return null;
    },

    propertyValidationRules() {
      return this.properties
        .map(property => {
          if (property.code === propertyCode.phone) {
            return {
              [property.code]: {
                required: requiredIf(() =>
                  this.havePropertyCode(property.code)
                ),
                minLength: minLength(this.phone_length),
                maxLength: maxLength(this.phone_length),
              },
            };
          } else if (property.code === propertyCode.email) {
            return {
              [property.code]: {
                required: requiredIf(() =>
                  this.havePropertyCode(property.code)
                ),
                email,
              },
            };
          } else {
            return {
              [property.code]: {
                required: requiredIf(() =>
                  this.havePropertyCode(property.code)
                ),
              },
            };
          }
        })
        .reduce((acc, cur) => ({ ...acc, ...cur }), {});
    },
  },
  methods: {
    isPhonePropertyCode(code) {
      return code === propertyCode.phone;
    },
    onToggleEdit(value) {
      this.isServiceNumberForm = value;
    },
    havePropertyCode(code) {
      return this.properties.find(p => p.code === code) !== undefined;
    },
    inputValidationError(property) {
      if (property) {
        if (
          this.$v.depositField[property.code].$error ||
          this.errors[property.code]
        ) {
          if (this.errors[property.code]) {
            return this.errors[property.code][0];
          }
          if (!this.$v.depositField[property.code].required) {
            return this.$t("deposit.please_input_the_var_value", {
              label: property.label,
            });
          } else {
            if (this.isPhonePropertyCode(property.code)) {
              if (
                !this.$v.depositField[property.code].minLength ||
                !this.$v.depositField[property.code].maxLength
              ) {
                return this.$t("deposit.the_var_value_is_length_not_valid", {
                  label: property.label,
                  length: this.phone_length,
                });
              }
            }
            if (
              property.code === propertyCode.email &&
              !this.$v.depositField[property.code].email
            ) {
              return this.$t("deposit.the_var_value_is_not_valid", {
                label: property.label,
              });
            }
          }
        }
      }
      return "";
    },
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },

    handleDepositForm() {
      if (!this.$v.invalid) {
        if (this.operator.code === operatorCode.waveCIV) {
          this.openModal({
            title: this.$t("common.important"),
            message: this.$t("common.wave_confirm", {
              name: this.form["name"],
              amount: this.amount,
            }),
          });
        } else if (this.amountTaxConfig) {
          const tax_value = this.amountTaxConfig.is_percent
            ? (this.amountTaxConfig.tax * this.depositField.amount) / 100
            : this.amountTaxConfig.tax;
          let confirmMessage = this.$t("deposit.deposit_tax_message", {
            amount: this.depositField.amount,
            currency: this.currency,
            tax_value,
            amount_without_tax: this.depositField.amount - tax_value,
          });
          if (this.operator.provider.credit_with_tax_amount) {
            confirmMessage = this.$t("deposit.deposit_tax_support_message", {
              amount: this.depositField.amount,
              currency: this.currency,
              tax_value,
              operator: this.operator.label,
              amount_with_tax: this.depositField.amount + tax_value,
            });
          }
          this.openModal({
            title: this.$t("common.important"),
            message: confirmMessage,
          });
        } else this.processToDeposit();
      }
    },

    processToDeposit() {
      this.hideModal();
      this.loading = true;
      this.error = null;
      this.errors = [];
      this.$store
        .dispatch("deposit/initiateDeposit", this.depositField)
        .then(data => {
          if (!isPending(data.deposit.status))
            this.$router.push({ name: "deposit" });
          else {
            if (data.redirect_url) location.assign(data.redirect_url);

            this.$emit("initiated", data);
          }
        })
        .catch(error => {
          if (error.data.message) {
            this.error = error.data.message;
          }
          switch (error.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;
          }
          this.loading = false;
        });
    },
  },
  validations() {
    return {
      depositField: {
        operator_id: { required },
        amount: {
          required,
          minValue: minValue(this.minDepositAmountValue),
          maxValue: maxValue(this.maxDepositAmountValue),
          validAmount: value => {
            if (value) {
              return parseFloat(value) % this.amountValidationModulo === 0;
            } else return true;
          },
        },
        ...this.propertyValidationRules,
      },
    };
  },
};
</script>

<style scoped></style>
