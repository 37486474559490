<template>
  <div class="card-body p-0">
    <form class="form mt-2 mb-5" @submit.prevent="handleServiceNumberForm">
      <div class="row justify-content-start recharges-input">
        <div class="form-group mt-0 row w-100">
          <div class="col-lg-12 col-xl p-l-30">
            <div v-if="showSvcNumberForm">
              <BaseInput
                :id="'svc-number-' + operator.id"
                v-model="numberField.svc_number"
                :countryCode="calling_code"
                :description="
                  $t('deposit.deposit_number') + operator.label + '.'
                "
                :disabled="loading"
                :errorMessage="svcNumberError"
                :isPhoneInput="true"
                :label="title"
                :placeholder="
                  $t('common.number_with_space') +
                    operator.label.toString().toLowerCase()
                "
                type="number"
                @input="$v.numberField.svc_number.$touch()"
              />
            </div>
            <div v-else>
              <BaseInput
                v-model="otpCode"
                :description="
                  `${$t('dashboard.labels.confirmation_code_precision')} ${
                    numberField.svc_number
                  }`
                "
                :errorMessage="otpCodeError"
                :label="title"
                :placeholder="
                  $t('dashboard.placeholders.give_confirmation_code')
                "
                @input="$v.otpCode.$touch()"
              />
            </div>
          </div>
          <div
            class="col-sm-12 col-lg m-t-30 d-flex justify-content-start recharges-button-container"
          >
            <div class="text-lg-left p-r-0 mr-2">
              <button
                v-if="canCancelForm"
                class="be-btn primary  badge-failure lg contact-button"
                @click.prevent="$emit('cancel')"
                :disabled="loading"
              >
                {{ $t("common.cancel") }}
              </button>
            </div>
            <BaseButton
              :disabled="$v.$invalid || loading"
              :loading="loading"
              :show-loading="true"
              buttonClass="be-btn primary lg blue mb-0 i-left ml-2"
            >
              {{ confirmBtnLabel }}
            </BaseButton>
          </div>
        </div>
        <div
          class="form-group mt-0 row w-100 justify-content-end"
          v-if="numberInit && otpCodeInit"
        >
          <div class="col-auto">
            <span class="f-w-600" v-if="counter > 0">
              {{ `Patienter encore ${counter}s svp !` }}
            </span>
            <a v-else href="#" @click.prevent="resendOperatorNumberOtpCode">
              <em class="f-w-600">{{ $t("utils.send_code") }}</em>
            </a>
          </div>
        </div>
      </div>
      <p
        v-if="numberInit && !numberInit.is_confirm"
        class="alert-dismissible alert-bewallet-warning d-flex align-items-center border-dashed-blue"
      >
        <i class="icon-info-fill" />
        <span>
          <b>{{ $t("common.attention") }}: </b>
          {{ $t("dashboard.messages.code_sent") }}
        </span>
      </p>
    </form>
    <b-modal
      id="operator_number_modal"
      ref="confirmPhoneNumber"
      no-fade
      size="md"
      centered
    >
      <template slot="modal-header">
        <span />
        <span class="modal-title">
          {{ modalData && modalData.title }}
        </span>
        <span class="pointer" @click="hideModal">
          <i class="icon-close f-24" />
        </span>
      </template>
      <div class="text-center">
        <div class="info-background">
          <i class="icon-info-fill"></i>
        </div>
        {{ modalData && modalData.message }}
      </div>
      <template slot="modal-footer">
        <span class="f-w-600 fermer mr-4 pointer" @click="hideModal">
          {{ $t("common.cancel") }}
        </span>
        <button
          class="ml-4 be-btn primary lg blue"
          @click="handleServiceNumberForm(true)"
        >
          {{ $t("common.yes_continue") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { maxLength, minLength, requiredIf } from "vuelidate/lib/validators";
import { returnBoolValue } from "@/helpers/helpers";

export default {
  name: "OperatorPhoneNumber",
  props: {
    operator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      svcNumberError: null,
      numberField: {
        operator_id: null,
        svc_number: null,
      },
      numberInit: null,
      otpCodeInit: null,
      otpCodeError: null,
      otpCode: null,

      modalData: null,
      counter: null,
      interval: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["calling_code", "currency", "phone_length"]),
    canValidateNumber() {
      return returnBoolValue(this.operator?.setting.validate_number ?? false);
    },
    maxServiceNumber() {
      return this.operator.setting?.max_number
        ? parseInt(this.operator.setting.max_number)
        : 0;
    },
    showSvcNumberForm() {
      return this.numberInit === null;
    },
    title() {
      return this.showSvcNumberForm
        ? this.maxServiceNumber > 1
          ? this.$t("dashboard.add_recharge_number") +
            ` (${this.operator.numbers.length + 1}/${this.maxServiceNumber})`
          : this.$t("dashboard.config_recharge_number")
        : this.$t("dashboard.labels.give_confirmation_code");
    },
    confirmText() {
      return this.numberInit
        ? this.$t("dashboard.save_contact")
        : this.$t("common.maximum") +
            ` ${this.maxServiceNumber} ` +
            this.$t("deposit.cant_change_number_desc_after");
    },
    canCancelForm() {
      return this.operator.numbers.length > 0;
    },
    confirmBtnLabel() {
      return this.showSvcNumberForm
        ? this.$t("common.sms")
        : this.$t("common.save");
    },
    inputPhoneLength() {
      return this.operator.country?.phone_length ?? this.phone_length;
    },
    isConfirmationCode() {
      return this.numberInit !== null;
    },
  },
  methods: {
    handleServiceNumberForm(validation = false) {
      if (!this.$v.invalid) {
        if (validation) {
          this.hideModal();
          this.loading = true;
          if (this.numberInit) {
            this.$store
              .dispatch("deposit/verifyOperatorNumber", {
                number_id: this.numberInit.id,
                otp: this.otpCode,
              })
              .then(() => this.$emit("cancel"))
              .catch(err => {
                this.otpCodeError =
                  err.response?.data.message ?? err.message ?? null;

                this.setCounterInterval();
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.numberField.operator_id = this.operator.id;
            this.$store
              .dispatch("deposit/createOperatorNumber", this.numberField)
              .then(({ data, otp_code }) => {
                this.numberInit = data;
                this.otpCodeInit = otp_code;

                this.setCounterInterval();
              })
              .catch(err => {
                this.svcNumberError =
                  err.response?.data.message ?? err.message ?? null;
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else
          this.openModal({
            title: this.$t("dashboard.labels.did_you_want_to_save"),
            message: this.confirmText,
          });
      }
    },

    resendOperatorNumberOtpCode() {
      if (this.numberInit && this.counter <= 0 && this.otpCodeInit) {
        this.loading = true;
        this.$store
          .dispatch("deposit/resendOperatorNumberOtpCode", this.otpCodeInit.id)
          .then(() => {
            this.setCounterInterval();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    setCounterInterval() {
      this.counter = 120;
      this.interval = setInterval(() => {
        this.counter--;
        if (this.counter === 0) clearInterval(this.interval);
      }, 1000);
    },

    openModal(row) {
      this.modalData = row;
      this.$refs.confirmPhoneNumber.show();
      this.svcNumberError = null;
    },
    hideModal() {
      this.$refs.confirmPhoneNumber.hide();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  validations() {
    return {
      numberField: {
        svc_number: {
          required: requiredIf(() => !this.isConfirmationCode),
          minLength: minLength(this.inputPhoneLength),
          maxLength: maxLength(this.inputPhoneLength),
        },
      },
      otpCode: {
        required: requiredIf(() => this.numberInit !== null),
        minLength: minLength(4),
      },
    };
  },
};
</script>

<style scoped></style>
